import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VAutocomplete,{attrs:{"items":_vm.choices,"rules":_vm.rules,"chips":_vm.multiple,"placeholder":_vm.description,"multiple":_vm.multiple,"value":null,"hide-no-data":"","return-object":"","auto-select-first":"","disabled":_vm.fieldDisabled},on:{"change":_vm.setChanged},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v("> "),_c('div',[_vm._v(" "+_vm._s(_vm.hint)+" ")])])],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Custom Value")])])]}}]),model:{value:(_vm.shown),callback:function ($$v) {_vm.shown=$$v},expression:"shown"}},[_c(VList,[_c(VListItem,[_c(VTextField,{attrs:{"autofocus":"","label":"Custom Value"},model:{value:(_vm.customValue),callback:function ($$v) {_vm.customValue=$$v},expression:"customValue"}})],1),_c(VListItem,[_c(VRow,[_c(VCol,{attrs:{"cols":"7"}},[_c(VBtn,{attrs:{"color":"grey"},on:{"click":_vm.closeMenu}},[_vm._v(" CANCEL ")])],1),_c(VCol,{attrs:{"cols":"5"}},[_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.addCustomValue()}}},[_vm._v(" ADD ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }