<template>
    <v-row>
        <v-col cols="2">
            <v-card flat min-width="70px">
                <v-card-text class="px-0">
                    {{ $t(name) }}
                </v-card-text>
            </v-card>
        </v-col>

        <v-col>
            <v-card flat class="ml-2">

                <v-text-field v-if="['string', 'number', 'timestamp', 'decimal', 'longitude', 'latitude', 'email'].indexOf(inputType) >= 0"
                    v-model="inputValue"
                    :rules="rules"
                    :placeholder="description"
                    :readonly="readonly"
                    :solo="readonly"
                    :step="step"
                    :min="min"
                    :max="max"
                    :type="['string', 'email'].indexOf(inputType) < 0 ? 'number' : 'text'"
                    @input="setChanged()">
                </v-text-field>

                <v-textarea v-else-if="inputType === 'text'"
                    v-model="inputValue"
                    :rules="rules"
                    :placeholder="description"
                    :readonly="readonly"
                    :solo="readonly"
                    @input="setChanged()">
                </v-textarea>

                <v-text-field v-else-if="inputType === 'password'"
                    v-model="inputValue"
                    :append-icon="showPasswd ? 'mdi-eye-off' : 'mdi-eye'"
                    :rules="rules"
                    :type="showPasswd ? 'text' : 'password'"
                    hint="At least 8 characters"
                    @click:append="showPasswd = !showPasswd">
                </v-text-field>

                <v-select v-else-if="inputType === 'select' && !customizable"
                    v-model="inputValue"
                    :items="selectList"
                    :rules="rules"
                    :placeholder="description"
                    :multiple="!!multiple"
                    @change="setChanged()">
                </v-select>

                <CustomSelect v-else-if="inputType === 'select'"
                    :type="customizable"
                    :value="inputValue"
                    :items="selectList"
                    :rules="rules"
                    :multiple="multiple"
                    :description="description"
                    :filter="filter"
                    v-on:input-changed="setCustomSelected(arguments[0])"
                />

                <v-autocomplete v-else-if="inputType === 'autocomplete' && !customizable"
                    v-model="inputValue"
                    :items="selectList"
                    :rules="rules"
                    :placeholder="description"
                    :multiple="multiple"
                    :value="null"
                    hide-no-data
                    auto-select-first
                    return-object
                    @input="searchInput=null"
                    :search-input.sync="searchInput"
                    @change="setChanged()">
                    <div>
                        {{ hint }}
                    </div>
                </v-autocomplete>

                <CustomAutocomplete v-else-if="inputType === 'autocomplete'"
                    :type="customizable"
                    :value="inputValue"
                    :items="selectList"
                    :rules="rules"
                    :multiple="multiple"
                    :description="description"
                    :filter="filter"
                    v-on:input-changed="setCustomSelected(arguments[0])"
                />

                <v-combobox v-else-if="inputType === 'combobox'"
                    v-model="inputValue"
                    :items="selectList"
                    :multiple="false"
                    auto-select-first
                    @change="setChanged()">
                </v-combobox>

                <v-checkbox v-else-if="inputType === 'bool'"
                    v-model="inputValue"
                    :rules="rules"
                    :label="description"
                    @click="setChanged()">
                </v-checkbox>

                <div v-else-if="inputType === 'upload'">
                    <v-file-input
                        show-size
                        :counter="multiple"
                        :multiple="multiple"
                        accept="image/*"
                        :label="name"
                        clearable
                        chips
                        @change="setUploadFiles($event)"
                    >
                        <template v-slot:selection="{ text, index }">
                            <v-chip
                                small
                                close
                                label
                                outlined
                                @click:close="removeUploadFile(index)"
                                >
                                {{ text }}
                            </v-chip>
                        </template>
                    </v-file-input>

                    <v-chip  v-for="(uFile, idx) in this.inputValue"
                        :key="idx+'_upload'"
                        small
                        close
                        label
                        outlined
                        @click:close="removeAttachedFile(idx)"
                        >
                        {{ uFile }}
                    </v-chip>
                </div>

                <v-menu v-else-if="inputType === 'date'"
                    v-model="dateMenu[colId]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">

                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="inputValue"
                                        :label="description"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        clearable
                                        @change="setChanged()"
                                        v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="inputValue"
                        :max="max === undefined ? max : '2050-12-01 00:00:00'"
                        :min="min === undefined ? min : '1970-01-01 00:00:00'"
                        @input="dateMenu[colId] = false"
                        @change="setChanged()">
                    </v-date-picker>
                </v-menu>

                <InputDatetime v-else-if="'datetime'"
                    :datetime="inputValue"
                    :optional="!required"
                    v-on:input-changed="setCustomDatetime(arguments[0])">
                </InputDatetime>

                <div class="body-2 grey--text text--darken-1 input-row-info">
                    {{ info }}
                </div>
            </v-card>
        </v-col>

        <v-col v-if="colId === 'computer' && inputValue" cols="1" style="min-width:60px;">
            <v-progress-circular class="mt-2"
                :value="diskSpace"
                :rotate="-90"
                :width="6"
                :size="50"
                :color="diskSpace < 75 ? 'success' : diskSpace < 90 ? 'warning' : 'error'"
            >
                <div class="grey--text text--darken-2">{{ diskSpaceValue }}</div>
            </v-progress-circular>
        </v-col>

        <v-col v-else-if="colId === 'router' && inputValue" cols="1" style="min-width:60px;">
            <v-btn text
                class="mt-3"
                target="_blank"
                :href="inputValue.link">
                <v-icon>
                    mdi-open-in-new
                </v-icon>
            </v-btn>
        </v-col>

        <v-col v-else-if="colId === 'com' && systemId" cols="1" style="min-width:60px;">
            <v-btn text
                class="mt-3"
                @click="getComPorts()">
                <v-icon>
                    mdi-refresh
                </v-icon>
            </v-btn>
        </v-col>

        <v-col cols="1" style="min-width:90px;">

            <v-card flat v-if="inputValue">
                <v-btn v-if="colId === 'archive' && (inputValue.constructor === String)"
                        text
                        class="mt-3"
                        target="_blank"
                        :href="`https://mksiteview.mktimelapse.com/mk/${inputValue}`">
                    <v-icon>
                        mdi-link
                    </v-icon>
                </v-btn>

                <v-btn v-else-if="['housing', 'computer', 'camera', 'lens', 'control', 'switch', 'dryer'].indexOf(colId) >= 0 && inputValue.value"
                        text
                        class="mt-3"
                        :to="( (colId === 'computer' && (refValue && refValue.computer)) ? `/computer/${refValue.computer.id}` : `/inventory/${inputValue.value}`)">
                    <v-icon>
                        mdi-link
                    </v-icon>
                </v-btn>

                <v-btn v-else-if="colId === 'router' && inputValue.link"
                        text
                        class="mt-3"
                        :to="`/inventory/${inputValue.value}`">
                    <v-icon>
                        mdi-link
                    </v-icon>
                </v-btn>

                <v-btn v-else-if="colId === 'projectId' && inputValue.value"
                        text
                        class="mt-3"
                        :to="'/project/'+inputValue.value">
                    <v-icon>
                        mdi-link
                    </v-icon>
                </v-btn>

                <v-btn v-else-if="['sim1', 'sim2', 'simSwitch'].indexOf(colId) >= 0 && phoneNumber"
                        text
                        class="mt-3"
                        :href="`tel:${phoneNumber}`">
                    <v-icon>
                        mdi-phone
                    </v-icon>
                </v-btn>

            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { rest } from '../_helpers'
import CustomSelect from '@/components/CustomSelect.vue'
import CustomAutocomplete from '@/components/CustomAutocomplete.vue'
import InputDatetime from '@/components/InputDatetime.vue'

export default {
    props: {
        systemId: Number,
        colId: String,
        name: String,
        inputType: String,
        value: [Number, String, Array, Object, Boolean],
        refValue: [Number, String, Array, Object, Boolean],
        selection: Array,
        rules: Array,
        description: String,
        multiple: Boolean,
        readonly: Boolean,
        required: Boolean,
        max: {
            default() {
                return undefined
            },
            type: String,
        },
        min: {
            default() {
                return undefined
            },
            type: String,
        },
        step: {
            default() {
                return 1
            },
            type: Number,
        },
        hint: {
            default() {
                return null
            },
            type: String,
        },

        customizable: {
            default() {
                return null
            },
            type: String,
        },
        filter: {
            default() {
                return null
            },
            type: String,
        },
    },

    components: {
        CustomSelect,
        CustomAutocomplete,
        InputDatetime,
    },

    data: () => ({
        inputValue: null,

        showPasswd: false,
        uploadFiles: [],

        dateMenu: {},
        selectList: [],
        info: null,
        searchInput: null,
    }),

    computed: {
        phoneNumber() {
            let number = null
            if (this.inputValue.number) {
                number = `${this.inputValue.countryCode ? this.inputValue.countryCode : ''}${this.inputValue.number ? this.inputValue.number : ''}`
            }
            return number
        },

        diskSpace() {
            const percentFree = this.inputValue.totalSpace ? (100 / this.inputValue.totalSpace) * this.inputValue.freeSpace : 0
            return 100 - percentFree
        },

        diskSpaceValue() {
            return Math.round(this.diskSpace) < 100 ? `${Math.round(this.diskSpace)}%` : 'X'
        },
    },

    methods: {
        setChanged() {
            if (this.inputType === 'bool' && !this.inputValue) {
                this.inputValue = false
            }
            if (this.inputValue) {
                this.info = this.inputValue.info
            }
            this.$emit('input-changed', this.inputValue, this.customizable)
        },

        setCustomSelected(value) {
            this.$emit('input-changed', value, this.customizable)
        },

        setCustomDatetime(value) {
            this.$emit('input-changed', value.format())
        },

        setUploadFiles(files) {
            this.uploadFiles = files ? files : []
            if (!Array.isArray(files)) {
                this.uploadFiles = [this.uploadFiles]
            }
            this.$emit('set-files', this.uploadFiles)
        },

        removeUploadFile(idx) {
            this.uploadFiles.splice(idx, 1)
            this.$emit('set-files', this.uploadFiles)
        },

        removeAttachedFile(idx) {
            this.inputValue.splice(idx, 1)
            this.setChanged()
        },

        getComPorts() {
            rest.serialPortList(this.systemId)
                .then(res => {
                    if (res.success) {
                        const ports = []
                        res.ports.forEach(port => {
                            ports.push({
                                id: port.path,
                                name: port.path,
                                text: `${port.path} (${port.manufacturer})`,
                                value: port.path,
                            })
                        })

                        this.selectList = ports
                    }
                })
                .catch(() => {})
        },
    },

    created() {
        this.inputValue = this.value
        this.info = this.inputValue ? this.inputValue.info : null
        this.selectList = this.selection
    },

    watch: {
        value(val) {
            this.inputValue = val
        },
        selection(val) {
            this.selectList = val
        },
    },
}
</script>

<style>
</style>
