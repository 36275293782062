<template>
    <div style="height:100%">

        <v-card class="px-3 py-3" flat>
            <v-container class="py-0 my-0">
                <!--<v-row v-if="type === 'project' && inputData && Object.keys(inputData).length > 0 && !inputData.enabled"
                    class="mt-3">
                    <v-col></v-col>
                    <v-col><span class="error--text">+++ {{ $t('archived') }} +++</span></v-col>
                    <v-col></v-col>
                </v-row>-->

                <v-row>
                    <!-- TITLE -->
                    <v-col v-if="title">
                        <v-container>
                            <v-card flat>
                                <v-card-text class="px-0 headline">
                                    {{ title }}
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-col>
                </v-row>

                <!-- FORM -->
                <v-form ref="form"
                        :id="type+'-form'"
                        v-model="valid"
                        v-if="inputFields.length > 0"
                        lazy-validation
                        class="input-container">

                    <v-container>
                        <div class="spinner"
                            v-if="showSpinner"></div>

                        <v-tabs
                            class="input-tabs"
                            :style="{height: inputFields.length > 1 ? 'unset' : 0}"
                            v-model="modelTabs"
                            show-arrows
                            grow
                        >
                            <v-tab
                            v-for="(part, idx) in inputFields"
                            :key="idx"
                            :href="`#tab-${idx}`"
                            >
                                {{ inputFields.length > 1 ? part.name ? $t(part.name) : $t('common') : '' }}
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="modelTabs">
                            <v-tab-item
                                v-for="(part, idx) in inputFields"
                                :key="idx"
                                :value="`tab-${idx}`"
                                eager
                            >
                                <!-- JUST INPUT FIELDS -->
                                <template>
                                    <div class="input-section"
                                        v-for="(input, iidx) in part.data"
                                        :key="iidx+'_sec'">

                                        <InputMaskTabRow
                                            :systemId="selectedId"
                                            :colId="input.col"
                                            :required="input.required"
                                            :name="input.name"
                                            :inputType="input.type"
                                            :customizable="input.customizable"
                                            :description="input.desc"
                                            :readonly="!!input.readonly"
                                            :value="inputs[input.col] != null ? inputs[input.col] : (input.default ? input.default : undefined)"
                                            :refValue="inputs[input.col] != null ? inputs[input.col] : undefined"
                                            :selection="selects[input.col]"
                                            :rules="getInputRules(input)"
                                            :multiple="!!input.multiple"
                                            :hint="hints[input.col]"
                                            :max="input.col === 'startDate' ? endDate : `${input.max}`"
                                            :min="input.col === 'endDate' ? startDate : `${input.min}`"
                                            :step="input.step"
                                            :filter="input.filterCol"
                                            v-on:input-changed="setChanged(input.col, arguments[0], input.customizable, input.filterCol)"
                                            v-on:set-files="setUploadFiles(arguments[0])"
                                        />

                                    </div>

                                </template>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-container>

                    <v-container v-if="!showSpinner">
                        <v-row class="mb-0" v-if="Object.keys(hint).length > 0">
                            <v-btn
                                @click="hint.link ? hint.link : true"
                                text>
                                <v-icon
                                    :color="hint.color"
                                    class="mr-1">

                                    {{ hint.icon }}

                                </v-icon>

                                {{ hint.text }}
                            </v-btn>
                        </v-row>

                        <v-row class="mb-3" justify="end">
                            <v-checkbox v-if="confirmable !== false"
                                v-model="agree"
                                :rules="[v => !!v || 'You must agree to continue!']"
                                :label="$t('confirm')"
                                style="min-width:175px;">
                            </v-checkbox>
                        </v-row>

                        <v-row style="padding-left:5px;" justify="end">
                            <v-btn :disabled="!valid"
                                color="success"
                                class="ma-2"
                                :class="(valid ? 'valid' : 'nope')"
                                @click="validate(false)">
                                {{ $t('Save') }}
                            </v-btn>

                            <v-btn v-if="['system', 'project'].indexOf(type) >= 0 && !selectedId"
                                :disabled="!valid"
                                color="success"
                                class="ma-2"
                                :class="(valid ? 'valid' : 'nope')"
                                @click="validate(true)">
                                {{ $t('Save & Enter') }}
                            </v-btn>

                            <v-btn v-if="id < 0"
                                color="error"
                                class="ma-2"
                                @click="reset">
                                {{ $t('Reset') }}
                            </v-btn>
                        </v-row>

                    </v-container>
                </v-form>

            </v-container>
        </v-card>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import { rest } from '../_helpers'
import { inputToRecord } from '../_helpers/misc'
// import CustomSelect from '@/components/CustomSelect.vue'
import InputMaskTabRow from '@/components/InputMaskTabRow.vue'

const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        refId: Number,
        refreshData: Boolean,
        type: String,
        title: String,
        inputData: {
            default() {
                return {}
            },
            type: Object,
        },
        selectedId: Number,
        fixedData: {
            default() {
                return {}
            },
            type: Object,
        },
        confirmable: {
            default() {
                return true
            },
            type: Boolean,
        },
        preValidate: {
            default() {
                return Promise.resolve(true)
            },
            type: Function,
        },
        hint: {
            default() {
                return {}
            },
            type: Object,
        },
    },

    components: {
        // CustomSelect,
        InputMaskTabRow,
    },

    data: () => ({
        changed: {},
        attachments: [],
        uploadFiles: [],
        objectReturns: [],
        modelTabs: 0,
        items: [
            { text: 1 },
            { text: 2 },
            { text: 3 },
        ],
        inputDataDefaults: {},
        showSpinner: true,

        valid: false,

        selects: { projectId: [] },
        agree: false,
        inputFields: [],
        dateMenu: {},
        showPasswd: false,

        inputs: {},

        startDate: undefined,
        endDate: undefined,

        readonly: [],

        hints: {},
        sections: [],
        oldType: null,
        oldSelectedId: null,

        rules: {
            required: [
                v => !!v || 'Field is required',
            ],
            name: [
                v => (v && v.length >= 100) || 'Name must be less than 100 characters',
            ],
            number: [
                v => (!v || !Number.isNaN(parseInt(v, 10))) || 'Has to be a number',
                v => (!v || v >= 0) || 'Number must be positive',
                v => (!v || (v <= 99999999)) || 'Number must be less than 99999999',
            ],
            latitude: [
                v => (!v || !Number.isNaN(parseInt(v, 10))) || 'Has to be a number',
                v => (!v || v >= -90) || 'Latitude must be between -90 and 90',
                v => (!v || (v <= 90)) || 'Latitude must be between -90 and 90',
            ],
            longitude: [
                v => (!v || !Number.isNaN(parseInt(v, 10))) || 'Has to be a number',
                v => (!v || v >= -180) || 'Longitude must be between -180 and 180',
                v => (!v || (v <= 180)) || 'Longitude must be between -180 and 180',
            ],
            email: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            panomax: [
                v => (!v || v.split('/').length === 2) || 'Valid Panomax field is INSTANCE/CAMERA_NUMBER',
                v => (!v || parseInt(v.split('/')[0], 10) > 0) || 'First field term has to be a number',
                v => (!v || parseInt(v.split('/')[1], 10) > 0) || 'Second field term has to be a number',
            ],
            siteview: [
                v => (!v || v.split('/').length === 3) || 'Valid Siteview field is SLUG/INSTANCE/NUMBER',
                v => (!v || parseInt(v.split('/')[1], 10) > 0) || 'Second field term has to be a number',
                v => (!v || parseInt(v.split('/')[2], 10) > 0) || 'Third field term has to be a number',
            ],
            select: [
            ],
        },

        customValues: {},
        filterKeys: [],
    }),

    computed: {
        ...mapState({
            account: 'account',
        }),

        inputDataInt() {
            const result = Object.keys(this.inputData).length > 0 ? this.inputData : this.inputDataDefaults
            return result
        },
    },

    methods: {
        ...mapActions({
            showSnackbar: 'page/showSnackbar',
            setSnackbar: 'page/setSnackbar',
            setFilter: 'input/setFilter',
        }),

        copyContent(copyData) {
            const copyText = document.getElementById(copyData)
            navigator.clipboard.writeText(copyText.textContent)
        },

        setChanged(key, value, customType, filter) {
            if (filter && value && this.inputs[filter]) {
                value.filter = this.inputs[filter].name.toLowerCase()
                this.selects[key].push(value)
                this.$set(this.selects, key, this.selects[key])
            }
            if (this.changed[key]) {
                const changeData = { ...this.changed[key], ...{ to: value } }
                this.$set(this.changed, key, changeData)
            } else {
                this.$set(this.changed, key, { from: this.inputs[key], to: value, type: key })
            }
            const oldValue = this.inputs[key]
            this.$set(this.inputs, key, value)

            if (this.filterKeys.indexOf(key) >= 0) {
                this.setFilter({ name: key, value: this.inputs[key].name })
            }

            if (customType) {
                this.$set(this.inputs, customType, { name: value !== null ? value.name : null })
            }

            if (key === 'startDate' && value) {
                this.startDate = value
            } else if (key === 'endDate' && value) {
                this.endDate = value
            }
        },

        getUploadAltName(fileName, count) {
            return `${this.type}_${this.inputDataInt.id}_${count}_${fileName.replaceAll(',', '_')}`
        },

        setUploadFiles(files) {
            this.uploadFiles = files
        },

        validate(enter) {
            this.showSnackbar(false)

            if (this.$refs.form.validate()) {
                if (Object.hasOwnProperty.call(this.inputDataInt, 'id')) {
                    this.inputs.id = this.inputDataInt.id
                }
                if (Object.hasOwnProperty.call(this.inputDataInt, 'refId')) {
                    this.inputs.refId = this.inputDataInt.refId
                }
                if (Object.hasOwnProperty.call(this.inputDataInt, 'refType')) {
                    this.inputs.refType = this.inputDataInt.refType
                }

                const countAtt = this.attachments.length
                const newFiles = this.uploadFiles.map((x, idx) => this.getUploadAltName(x.name, idx + countAtt))
                this.attachments = [...new Set([...this.attachments, ...newFiles])]

                if (Object.prototype.hasOwnProperty.call(this.inputs, 'attachments')) {
                    this.inputs.attachments = this.attachments
                }

                const record = inputToRecord(this.inputs, this.readonly, this.objectReturns)
                if (this.type === 'note') {
                    record.userId = this.account.user.user.id
                }
                if (this.type === 'action') {
                    record.systemId = this.inputData.systemId
                }
                if (this.type === 'config') {
                    record.systemId = this.refId
                }

                if (this.inputData.referenceId) {
                    record.referenceId = this.inputData.referenceId
                }
                if (this.inputData.referenceType) {
                    record.referenceId = this.inputData.referenceType
                }
                record.rId = this.refId

                const isNew = !record.id

                const preValidation = this.preValidate(this.changed)

                const saveRecord = () => {
                    rest.setItem(this.type, record)
                        .then(response => {
                            if (!response.error
                                && response.affectedRows) {
                                if (!record.id && response.insertId) {
                                    record.id = response.insertId
                                }

                                if (this.inputs.projectId) {
                                    if (!Object.hasOwnProperty.call(record, 'project')) {
                                        record.project = {}
                                    }
                                    record.project.name = this.inputs.projectId.text
                                }
                                if (this.inputs.computer) {
                                    record.computerName = this.inputs.computer.text
                                }

                                const tableRecord = ({
                                    ...this.inputDataInt,
                                    ...this.inputs,
                                    ...{ updatedAt: moment().utc().format() },
                                    ...record,
                                })
                                if (this.type === 'contact') {
                                    tableRecord.projects = this.inputs.projects
                                }
                                if (!Object.hasOwnProperty.call(tableRecord, 'notes')) {
                                    tableRecord.notes = []
                                }

                                this.agree = false

                                this.$emit('edit-item-saved', {
                                    type: this.type,
                                    data: tableRecord,
                                    id: this.id,
                                })

                                this.setSnackbar({
                                    type: 'success',
                                    text: `${this.$t('Record Saved')} (ID ${record.id})`,
                                })
                                this.showSnackbar(true)

                                return record
                            }

                            if (response.error) {
                                this.setSnackbar({
                                    type: 'error',
                                    text: response.error.message,
                                })
                                this.showSnackbar(true)
                            }

                            return false
                        })
                        .then(result => {
                            if (result) {
                                const fileUploads = []
                                let fileCount = 0

                                this.uploadFiles.forEach(file => {
                                    const altName = this.getUploadAltName(file.name, countAtt + fileCount)
                                    fileCount += 1
                                    fileUploads.push(rest.upload(file, altName))
                                })

                                return fileUploads.length ? Promise.all(fileUploads) : false
                            }

                            return false
                        })
                        .then(result => {
                            if (isNew && result) {
                                let atts = []
                                result.forEach(fUpload => {
                                    atts = [...atts, ...fUpload.files]
                                })
                                record.attachments = atts.join(',')

                                return rest.setItem(this.type, record)
                            }

                            return true
                        })
                        .catch(error => {
                            // let errorLines = error.response.split(/[\n\r]+/)
                            this.setSnackbar({
                                type: 'error',
                                text: error.message, // errorLines[0].replace(/^\(.*\)/, '').trim()
                            })
                            this.showSnackbar(true)
                        })
                        .finally(() => {
                            if (enter) {
                                this.$router.push(`/${this.type}/${record.id}/report`)
                            }

                            if (this.$refs.form) {
                                this.$refs.form.resetValidation()
                            }
                        })
                }

                preValidation
                    .then(() => {
                        this.changed = {}
                        saveRecord()
                    })
            } else {
                this.setSnackbar({
                    type: 'error',
                    text: 'Validation failed. Required form fields have to be set.',
                })
                this.showSnackbar(true)
            }
        },

        reset() {
            this.$refs.form.reset()
        },

        getInputRules(item) {
            let ruleSet = []

            if (item.required) {
                ruleSet = ruleSet.concat(this.rules.required)
            }

            if (Object.hasOwnProperty.call(this.rules, item.type)) {
                ruleSet = ruleSet.concat(this.rules[item.type])
            }

            if (item.col === 'panomax' && item.table === 'system') {
                ruleSet = ruleSet.concat(this.rules.panomax)
            }

            if (item.col === 'archive' && item.table === 'system') {
                ruleSet = ruleSet.concat(this.rules.siteview)
            }

            if (item.type === 'number') {
                if (item.min !== undefined) {
                    ruleSet.push(v => v >= item.min || `Value is below ${item.min}`)
                }
                if (item.max !== undefined) {
                    ruleSet.push(v => v <= item.max || `Value is higher than ${item.max}`)
                }
            }

            return ruleSet
        },

        fillMask(mask, update) {
            mask.forEach(section => {
                // init open or closed sections
                this.sections.push([(['Inventory', 'SIM'].indexOf(section.open) >= 0)])

                section.data.forEach(input => {
                    let value = input.multiple ? [] : null

                    let multiCheckList = []
                    if (input.multiple && this.inputDataInt[input.col]) {
                        multiCheckList = Array.isArray(this.inputDataInt[input.col]) ? this.inputDataInt[input.col].map(x => x.id) : this.inputDataInt[input.col].split(',').map(x => Number.parseInt(x, 10))
                    }

                    // mark field readonly
                    if (input.readonly) {
                        this.readonly.push(input.col)
                    }

                    // special info data for this column. PROMISE?
                    if (this.inputDataInt[input.col] && input.info && input.info.charAt(0) === '$') {
                        /* rest.getSystemInfo(this.inputDataInt.id, (data) => {
                            if (data.length) {
                                input.desc = '(' + data[0][input.info.slice(1)] + 'ml)'
                            }
                        }) */
                    }

                    // date field
                    if (input.type === 'date' && this.inputDataInt[input.col]) {
                        value = moment(this.inputDataInt[input.col]).tz('Europe/Berlin').format('YYYY-MM-DD')

                    // select or autocomplete field
                    } else if (input.type === 'select' || input.type === 'autocomplete' || input.type === 'combobox') {
                        const empty = { value: null, text: '', info: '' }
                        let selectItems = []
                        this.hints[input.col] = ''

                        // select input value can be empty
                        if (input.empty) {
                            selectItems.push(empty)
                        }

                        for (const selectItem of input.select) {
                            selectItem.text = this.$t(selectItem.name)
                            selectItem.value = selectItem.id

                            if (selectItem.id === this.fixedData[input.col]) {
                                selectItems = [selectItem]
                                value = selectItem
                                break
                            // current select item is the selected item
                            } else {
                                if (input.multiple) {
                                    if (multiCheckList.includes(selectItem.id)) {
                                        value.push(selectItem)
                                    }
                                } else if (selectItem.id === this.inputDataInt[input.col]) {
                                    value = selectItem

                                    if (input.type === 'combobox') {
                                        value = [selectItem.name]
                                    }

                                    if (selectItem.info) {
                                        this.hints[input.col] = selectItem.info
                                    }
                                }
                            }

                            selectItems.push(selectItem)

                            if (!value && selectItem.default) {
                                value = selectItem
                            }
                        }

                        if (!update) {
                            this.selects[input.col] = selectItems
                        }
                    } else if (input.type === 'upload') {
                        this.attachments = this.inputDataInt[input.col] ? this.inputDataInt[input.col].split(',') : []
                        value = this.attachments
                    // every other type of field
                    } else {
                        value = this.inputDataInt[input.col]
                    }

                    this.inputs[input.col] = value
                })

                this.$emit('values-loaded', this.inputs)
            })
        },

        getInputFields() {
            if (this.type) {
                this.inputFields = []
                let query = null
                if (this.type === 'inventory') {
                    query = { type: this.inputData.invTypeId }
                }

                return rest.getInputData(this.type, this.selectedId, query)
                    .then(mask => {
                        if (mask) {
                            this.fillMask(mask)

                            this.setFilterByMask(mask)

                            this.inputFields = mask

                            this.showSpinner = false
                        }

                        return mask
                    })
            }

            return Promise.resolve(this.inputFields)
        },

        setFilterByMask(mask) {
            this.objectReturns = []
            mask.forEach(part => {
                if (part.data) {
                    this.filterKeys = []
                    part.data.forEach(item => {
                        if (item.type === 'autocomplete') {
                            this.objectReturns.push(item.col)
                        }

                        if (item.filterCol && this.filterKeys.indexOf(item.filterCol) < 0) {
                            this.filterKeys.push(item.filterCol)

                            if (this.inputs[item.filterCol]) {
                                this.setFilter({ name: item.filterCol, value: this.inputs[item.filterCol].name })
                            }
                        }
                    })
                }
            })
        },

        refillForm(force) {
            const mask = this.inputFields
            let getFields = Promise.resolve(mask)

            if (this.oldType !== this.type || force) {
                getFields = this.getInputFields()
                this.oldType = this.type
            }

            if (this.inputDataInt.id !== this.oldSelectedId || this.id < 0 || force) {
                getFields
                    .then(newMask => {
                        this.inputs = {}
                        this.inputId = this.inputDataInt.id
                        this.fillMask(newMask, true)

                        this.setFilterByMask(newMask)

                        this.inputFields = newMask
                        this.oldSelectedId = this.inputDataInt.id
                    })
            }

            this.agree = false
        },
    },

    mounted() {
        if (Object.keys(this.inputData).length <= 0 && this.type) {
            rest.getDefault(this.type)
                .then(item => {
                    this.inputDataDefaults = item
                })
        }
    },

    created() {
        this.oldType = this.type
        this.oldSelectedId = this.inputDataInt.id

        this.getInputFields()
    },

    watch: {
        id() {
            console.log('id refill')
            this.refillForm()
        },

        type() {
            console.log('type refill')
            this.refillForm(true)
        },

        inputData() {
            console.log('data refill')
            this.refillForm()
        },

        selectedId(val) {
            this.refillForm(true)
        },
    },

    beforeDestroy() {
        console.log('Destroy', this.changed)
    },
}
</script>


<style lang="stylus" scoped>
    .input-tabs
        height 0

    .v-form > .container.input-container
        padding 0

    .input-container .v-expansion-panel__header
        padding 0

    .input-container .v-expansion-panel
        box-shadow: none;

    .input-section
        margin-bottom 1.5em
</style>
