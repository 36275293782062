<template>
    <v-row>
        <v-col>
            <v-autocomplete
                v-model="selected"
                :items="choices"
                :rules="rules"
                :chips="multiple"
                :placeholder="description"
                :multiple="multiple"
                :value="null"
                hide-no-data
                return-object
                auto-select-first
                :disabled="fieldDisabled"
                @change="setChanged">>
                <div>
                    {{ hint }}
                </div>
            </v-autocomplete>
        </v-col>

        <v-col cols="2">
            <v-menu
                offset-y
                v-model="shown"
                :close-on-content-click="false">
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn icon
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                            >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Add Custom Value</span>
                    </v-tooltip>
                </template>

                <v-list>
                    <v-list-item>
                        <v-text-field
                            autofocus
                            v-model="customValue"
                            label="Custom Value">
                        </v-text-field>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="7">
                                <v-btn color="grey"
                                    @click="closeMenu">
                                    CANCEL
                                </v-btn>
                            </v-col>
                            <v-col cols="5">
                                <v-btn color="success"
                                    @click="addCustomValue()">
                                    ADD
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>

            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { rest } from '../_helpers'

export default {
    props: {
        type: String,
        value: [Array, Object],
        items: Array,
        rules: Array,
        multiple: Boolean,
        description: String,
        hint: {
            default() {
                return null
            },
            type: String,
        },
        filter: {
            default() {
                return null
            },
            type: String,
        },
    },

    data() {
        return {
            selected: this.value,
            choices: this.items,
            customValue: '',
            shown: false,
        }
    },

    components: {
    },

    computed: {
        ...mapState({
            input: 'input',
        }),

        fieldDisabled() {
            if (this.filter) {
                if (this.input.data.filter[this.filter]) {
                    this.filterChoices()
                    return false
                }
                return true
            }

            return false
        },
    },

    methods: {
        addCustomValue() {
            if (this.customValue) {
                if (this.type !== '=') {
                    rest.setItem(this.type, { name: this.customValue })
                        .then(response => {
                            if (response.success) {
                                const select = {
                                    id: response.insertId,
                                    name: this.customValue.toUpperCase(),
                                    text: this.customValue.toUpperCase(),
                                    value: response.insertId,
                                }

                                this.setCustomSelect(select)
                            }
                        })
                } else {
                    const select = {
                        id: this.customValue,
                        name: this.customValue,
                        text: this.customValue,
                        value: this.customValue,
                    }

                    this.setCustomSelect(select)
                }
            }
        },

        setCustomSelect(select) {
            this.choices.push(select)
            this.choices.sort((a, b) => a.name > b.name)
            if (this.multiple) {
                this.selected.push(select)
            } else {
                this.selected = select
            }

            this.setChanged()

            this.customValue = ''
            this.shown = false
        },

        setChanged() {
            this.$emit('input-changed', this.selected)
        },

        closeMenu() {
            this.shown = false
        },

        filterChoices() {
            const newChoices = []

            this.items.forEach(item => {
                const filterValue = this.input.data.filter[this.filter].toLowerCase()
                if (item.filter === filterValue) {
                    newChoices.push(item)
                }
            })

            this.choices = newChoices
        },
    },

    created() {
    },

    watch: {
        value(val) {
            this.selected = val
        },
    },
}
</script>

<style scoped>

</style>
